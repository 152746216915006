/* eslint-disable object-curly-spacing */
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const GbiBridged = (): React.ReactElement => {
  return (
    <section className='masthead headerWithText'>
      <div style={{ display: 'grid' }}>
        <StaticImage style={{ gridArea: '1/1' }} src='../images/triceonic-stategieumsetzung-digitalisiert-header.jpg' alt='Leistungsstarke Strategieumsetzung' placeholder='none' />
        <div style={{ gridArea: '1/1', position: 'relative' }} className="innerHeader">
          <h1>Ein Unternehmen. Viele Aufgaben. Volle Kraft.</h1>
          <div><p>Bringe Dein volles Potential auf die Straße und richte es zielsicher auf Zukunftsthemen aus.</p></div>
        </div>
      </div>
    </section>
  );
};

export default GbiBridged;
